import { Accordion, AccordionBody, AccordionHeader, Typography, Chip, TabPanel } from "@material-tailwind/react";
import React, { useContext, useEffect } from "react";
import DataContext from "../contexts/DataContext";


export default function ShopTab({}) {
    let dataCtx = useContext(DataContext);

    useEffect(()=>{

    }, []);

    function toVal(v) { return isNaN(v) ? 0 : parseFloat(v.toFixed(2))}
    function displayVal(v) {
        v = toVal(v);
        return v.toFixed(2)+' €'
    }

    function count(n) {
        if (!dataCtx.shopData[n]) return 0;
        return dataCtx.shopData[n].count;
    }

    function amount(n) {
        if (!dataCtx.shopData[n]) return 0
        return dataCtx.shopData[n].amount;
    }

    function sum(words) {
        if (typeof(words)=='string') words = [words];
        let v = 0;
        for (let f of fields) {
            let movWords = f.split(' ');
            let allFound = true;
            for (w of words) {
                if (movWords.indexOf(w)==-1) {
                    allFound = false;
                    break;
                }
            }
            if (allFound) v += amount(f)
        }
        return v;
    }

    const fields = [
        'entrata cash negozio',
        'entrata pos panda',
        'entrata cash panda',
        'uscita cash saverio',
        'uscita banca panda',
        'uscita cash panda',
        'uscita cash negozio'
    ]

    return <TabPanel value="shop">
        <Typography variant="h5" className="pt-4">Riepilogo movimenti</Typography>
        <hr/>
        {
            dataCtx.shopData ? <><div className="pt-3">
                {
                    fields.map((f,i)=> {
                        return             <div className="flex w-full space-x-0" key={i}>
                        <div className="w-24">
                        {f.indexOf('entrata')==0 ? <Chip className="text-center w-20" variant="ghost" size="sm" color="green" value="entrata" /> : <Chip className="text-center w-20" variant="ghost" size="sm" color="red" value="uscita" />}
                        </div>
                        <div className="w-56 font-normal">
                        {f} ({count(f)})</div>
                        <div className="w-32 text-left">{displayVal(amount(f))}</div>
                    </div>
        
                    })
                }
            </div>
            <Typography variant="h5" className="pt-4">Bilancio</Typography>
            <hr/>        

            <div className="flex w-full space-x-4">
                <div className="w-56 font-normal">Totale entrate</div>
                <div className="w-32 text-left">{displayVal(sum('entrata'))}</div>
            </div>
            <div className="flex w-full space-x-4">
                <div className="w-56 font-normal">Totale uscite</div>
                <div className="w-32 text-left">{displayVal(sum('uscita'))}</div>
            </div>
            
            <div className="flex w-full space-x-4">
                <div className="w-56 font-normal">Bilancio</div>
                <div className="w-32 text-left">{displayVal(sum('entrata')-sum('uscita'))}</div>
            </div>

            <div className="flex w-full space-x-4">
                <div className="w-56 font-normal">Bilancio cash negozio</div>
                <div className="w-32 text-left">{displayVal(amount('entrata cash negozio')-amount('uscita cash negozio')-amount('uscita cash saverio'))}</div>
            </div>

            <div className="flex w-full space-x-4">
                <div className="w-56 font-normal">Bilancio cash panda</div>
                <div className="w-32 text-left">{displayVal(amount('entrata cash panda')-amount('uscita cash panda'))}</div>
            </div>

            <div className="flex w-full space-x-4">
                <div className="w-56 font-normal">Bilancio banca panda</div>
                <div className="w-32 text-left">{displayVal(amount('entrata pos panda')-amount('uscita banca panda'))}</div>
            </div>


            </>
            : <div className="p-4"><i>Nessun dato caricato</i></div>
        }
        </TabPanel>
    
}