import { Accordion, AccordionBody, AccordionHeader, Chip, TabPanel } from "@material-tailwind/react";
import React, { useContext, useEffect } from "react";
import DataContext from "../contexts/DataContext";
import Utils from "../utils/Utils";



export default function SaverioTab({}) {
    let dataCtx = useContext(DataContext);

    useEffect(()=>{

    }, []);

    function toVal(v) { return isNaN(v) ? 0 : parseFloat(v.toFixed(2))}
    function displayVal(v) {
        v = toVal(v);
        return v.toFixed(2)+' €'
    }

    function getClubRows() {
        let rows = [];
        let tot = {cc:0, cash: 0, banca: 0};
        for (let row of dataCtx.saverioData) {
            let fields = row.mov.split('-').map(f=>f.trim());
            if(fields[1]=='carta di credito') fields[1] = 'cc';
            let entry = {dir: fields[0], date: row.date, note: row.note, cc: 0, cash: 0, banca: 0}
            entry[fields[1]] = row.amount;
            tot[fields[1]] += row.amount * (entry.dir == 'entrata' ? 1 : -1);
            rows.push(entry);
        }
        rows.push(tot);
        return rows;
    }

    return <TabPanel value="saverio">
        {
            dataCtx.saverioData ? <div>
            <div className="flex w-full font-semibold space-x-4">
                <div className="w-24"></div>
                <div className="w-28">data</div>
                <div className="w-32">banca</div>
                <div className="w-32">contanti</div>
                <div className="w-32">carta di credito</div>
                <div className="">note</div>
            </div>

            {
                getClubRows().map((row,i)=> {
                    return             <div className={'flex w-full space-x-4 ' + (row.dir ? 'font-normal' : 'font-semibold pt-2')}>
                    <div className="w-24">
                    {
                                row.dir ? (row.dir=='entrata' ? <Chip className="text-center w-20" variant="ghost" size="sm" color="green" value="entrata" /> : <Chip className="text-center w-20" variant="ghost" size="sm" color="red" value="uscita" />)
                                : <></>
                    }                        
                    </div>
                    <div className="w-28">{row.date ? row.date : 'bilancio'}</div>
                    <div className="w-32">{displayVal(row.banca)}</div>
                    <div className="w-32">{displayVal(row.cash)}</div>
                    <div className="w-32">{displayVal(row.cc)}</div>
                    <div className="">{row.note}</div>
                </div>
                })
            }
            </div>
            : <div className="p-4"><i>Nessun dato caricato</i></div>
        }
        </TabPanel>
    
}