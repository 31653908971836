import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from "react-dom/client";
import { Select, Option, Button, Card, Input } from "@material-tailwind/react";
import axios from 'axios';
import { ThemeProvider } from '@material-tailwind/react';
import { Spinner } from "@material-tailwind/react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";
import ClubTab from './components/ClubTab';
import DataContext, { DataContextProvider } from './contexts/DataContext';
import SaverioTab from './components/SaverioTab';
import Utils from './utils/Utils';
import IceTab from './components/IceTab';
import ShopTab from './components/ShopTab';


const AuthState = {
    AUTHENTICATED: 'AUTHENTICATED',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    AUTHENTICATING: 'AUTHENTICATING'
}


function View ({authState, setAuthState}) {
   let dataCtx = useContext(DataContext);
    const [currentTab, setCurrentTab] = useState('club');

    useEffect(() => {
        if (authState == AuthState.AUTHENTICATED) {
            dataCtx.getData();
        }
    }, [authState]);  
      
    
   const logout = async () => {
    try {
      await axios.get('/logout');
      setAuthState(AuthState.UNAUTHENTICATED);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='flex flex-col w-full'>

      <div className='flex py-6 gap-x-2'>
        <div className='w-72'>
            <Select label="Seleziona il mese" value={dataCtx.month}>
                {
                    dataCtx.getMonths().map((v,i)=>{
                        return <Option key={i} value={v} onClick={()=>dataCtx.setMonth(v)}>{v}</Option>
                    })
                }
            </Select>
        </div>
        <div className='w-56'>
            <Select label="Seleziona l'anno" value={dataCtx.year} >
                {
                    dataCtx.getYears().map((y,i)=>{
                        return <Option key={i} value={y} onClick={()=>dataCtx.setYear(y)}>{y}</Option>
                    })
                }
            </Select>
        </div>
        <div className='w-56'>
            <Button className='flex items-center gap-3' disabled={dataCtx.updating} onClick={()=>dataCtx.getReport(currentTab)}>AGGIORNA
                <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-3 w-3" 
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>            
            </Button>
        </div>
        <div className="text-sm flex items-center">
        {
          dataCtx.updating ? <span className='animate-pulse'>aggiornamento in corso...</span> : dataCtx.lastUpdated ? <span>Ultimo aggiornamento {Utils.toHumanDate(Date.parse(dataCtx.lastUpdated))}</span> : <></>
        }
        </div>


      </div>

      <Tabs value={currentTab}>
      <TabsHeader>
        <Tab value="club">Panda</Tab>
        <Tab value="shop">Negozio</Tab>
        <Tab value="ice">Pattinaggio pubblico</Tab>
        <Tab value="saverio">Saverio vs Panda</Tab> 
        <Tab value="restaurant">Ristorante</Tab>
      </TabsHeader>
      <TabsBody>
          <ClubTab />
          <ShopTab />
          <IceTab />                
          <SaverioTab />
          <TabPanel value="restaurant"> </TabPanel>
      </TabsBody>
        </Tabs>

    </div>
  );
}

function Login({setAuthState}) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const authenticate = async () => {
        try {
          const res = await axios.get('/authenticate', { auth: { username, password } });
          setAuthState(res.data.auth ? AuthState.AUTHENTICATED : AuthState.UNAUTHENTICATED);
        } catch (e) {
          console.log(e);
        }
    };

    return <Card color="transparent" shadow={false}>
      <div className='flex flex-col items-center'>
        <img src="images/panda.png" alt="Sporting Club Panda" width="50%"/>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <Input size="lg" label="Utente" onChange={e => setUsername(e.target.value)} />
            <Input type="password" size="lg" label="Password" onChange={e => setPassword(e.target.value)} />
          </div>
          <Button className="mt-6" fullWidth  onClick={authenticate}>
            Entra
          </Button>
        </form>
      </div>
      </Card>            
}

function App() {

  const [authState, setAuthState] = useState(AuthState.AUTHENTICATING);

  const checkAuth = async () => {
    try {
      const res = await axios.get('/check_auth');
      setAuthState(res.data.auth ? AuthState.AUTHENTICATED : AuthState.UNAUTHENTICATED);
    } catch (e) {
      setAuthState(AuthState.UNAUTHENTICATED);
      console.log(e);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);  


  return (
    <div className='w-full flex justify-center'>
    <div style={{width: '1280px'}} className="flex flex-col items-center">
        {
            (()=>{
                switch (authState) {
                    case AuthState.AUTHENTICATING:
                        return <Spinner className="h-12 w-12" color='purple' />
                    case AuthState.UNAUTHENTICATED:
                        return <Login setAuthState={setAuthState} />
                    default:
                        return <View authState={authState} setAuthState={setAuthState}  />
                }
            }).call(this)
        }
    </div>
    </div>
  );
}

export default App;


const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
    <React.StrictMode>
      <ThemeProvider>
        <DataContextProvider>
            <App />
        </DataContextProvider>
      </ThemeProvider>
    </React.StrictMode>
  );