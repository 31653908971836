import { Accordion, AccordionBody, AccordionHeader, Chip, TabPanel } from "@material-tailwind/react";
import React, { useContext, useEffect } from "react";
import DataContext from "../contexts/DataContext";


export default function IceTab({}) {
    let dataCtx = useContext(DataContext);

    useEffect(()=>{

    }, []);

    function toVal(v) { return isNaN(v) ? 0 : parseFloat(v.toFixed(2))}
    function displayVal(v) {
        v = toVal(v);
        return v.toFixed(2)+' €'
    }

    function getEntries() {
        let days = {};
        let tot = {pos: 0, cash:0};
        for (let row of dataCtx.iceData) {
            let fields = row.mov.split(' ').map(f=>f.trim());
            
            if (!days[row.date]) days[row.date] = {pos: 0, cash: 0}
            if (fields[0]=='pagamento') {
                tot.cash -= row.amount;
                days[row.date].cash -= row.amount;
            } else if (fields[1]=='pos') {
                tot.pos += row.amount;
                days[row.date].pos += row.amount;
            } else if (fields[1]=='contanti') {
                tot.cash += row.amount;
                days[row.date].cash += row.amount;
            }
        }
        days['totale'] = tot;
        return Object.entries(days);
    }

    return <TabPanel value="ice">
        {
            dataCtx.iceData ? <div>
            <div className="flex w-full font-semibold space-x-4">
                <div className="w-32">giorno</div>
                <div className="w-32">incasso pos</div>
                <div className="w-32">incasso contanti</div>
                <div className="w-32">incasso totale</div>
            </div>

            {
                getEntries().map((row,i)=> {
                    return             <div className={'flex w-full space-x-4 ' + (row[0]!='totale' ? 'font-normal' : 'font-semibold pt-2')}>
                    <div className="w-32">{row[0]}</div>
                    <div className="w-32">{displayVal(row[1].pos)}</div>
                    <div className="w-32">{displayVal(row[1].cash)}</div>
                    <div className="w-32">{displayVal(row[1].cash+row[1].pos)}</div>
                </div>
                })
            }
            </div>
            : <div className="p-4"><i>Nessun dato caricato</i></div>
        }
        </TabPanel>
    
}