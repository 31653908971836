import axios from 'axios';

const { createContext, useState, useEffect } = require("react");

const MONTHS = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
]

const YEARS = [
    (new Date()).getFullYear()-1,
    (new Date()).getFullYear(),
    (new Date()).getFullYear()+1
]


const DataContext = createContext({
    clubData: null, setClubData: ()=>{},
    saverioData: null, setSaverioData: ()=>{},
    iceData: null, setIceData: ()=> {},
    shopData: null, setShopData: ()=> {},
    year: YEARS[0], setYear: ()=>{},
    month: MONTHS[0], setMonth: ()=>{},
    updating: false, setUpdating: ()=>{},
    lastUpdated: null, setLastUpdated: ()=>{},
    getReport: ()=>{},
    getData: ()=>{},
    getMonths: ()=>{},
    getYears: ()=>{}
});


export function DataContextProvider({ children }) {

    const [clubData, setClubData] = useState(null);
    const [saverioData, setSaverioData] = useState(null);
    const [iceData, setIceData] = useState(null);
    const [shopData, setShopData] = useState(null);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [month, setMonth] = useState(MONTHS[(new Date()).getMonth()]);
    const [updating, setUpdating] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);

    function setData(res) {
        setLastUpdated(res.data.timestamp);
        setClubData(res.data.reports.club);
        setSaverioData(res.data.reports.saverio);
        setIceData(res.data.reports.ice);
        setShopData(res.data.reports.shop);
    }

    useEffect(()=>{
        context.getData();
    }, [month, year])

    const context = {
        clubData, setClubData,
        saverioData, setSaverioData,
        iceData, setIceData,
        shopData, setShopData,
        year, setYear,
        month, setMonth,
        updating, setUpdating,
        lastUpdated, setLastUpdated,
        getMonths: ()=>MONTHS,
        getYears: ()=>YEARS,
        getReport: async () =>{
            if (updating) return;
            setUpdating(true);

            try {
                let res = await axios.get('/report', {params: {month: MONTHS.indexOf(month)+1, year}});
                setData(res);
            } catch (e) {
                console.log(e);
            }
            setUpdating(false);
        },
        getData: async () =>{
            try {
                let res = await axios.get('/get_data', {params: {month: MONTHS.indexOf(month)+1, year}});
                console.log(res)
                setData(res);
            } catch (e) {
                console.log(e);
            }
        }

    }

    return (
        <DataContext.Provider value={context}>{children}</DataContext.Provider>
    )
}

export default DataContext;