import { Accordion, AccordionBody, AccordionHeader, Chip, TabPanel } from "@material-tailwind/react";
import React, { useContext, useEffect } from "react";
import DataContext from "../contexts/DataContext";
import Utils from "../utils/Utils";



export default function ClubTab({}) {
    const [open, setOpen] = React.useState(0);
    const [alwaysOpen, setAlwaysOpen] = React.useState(true);
   
    const handleAlwaysOpen = () => setAlwaysOpen((cur) => !cur);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    let dataCtx = useContext(DataContext);

    useEffect(()=>{

    }, []);

    function toVal(v) { return isNaN(v) ? 0 : parseFloat(v.toFixed(2))}

    function initialVal() { return {count: 0, entrata: {banca: 0, contanti: 0}, uscita: {banca:0, contanti:0}}}

    function addToActivity(entries, activity, direction, val) {
        if (!entries[activity]) entries[activity] = initialVal();
        if (!entries[activity][direction]) entries[activity][direction] = {};
        entries[activity][direction]['banca'] += toVal(val.banca); 
        entries[activity][direction]['contanti'] += toVal(val.contanti); 
        entries[activity].count += val.count;

    }
    function displayVal(v) {
        v = toVal(v);
        return v.toFixed(2)+' €'

    }
    function balanceDisplayVal(v) {
        return <span className={v < 0 ? 'text-red-500' : (v == 0 ? '' : 'text-green-500')}>{displayVal(v)}</span>
    }
    function getSummaryEntries() {
        let entries = {'totale': initialVal()};

        for (let field in dataCtx.clubData.fields) {
            let fields = field.split('-').map(s=>s.trim());
            let val = dataCtx.clubData.fields[field];
            let activity = fields[1];
            let direction = fields[0];


            if (activity=='stipendi' && (fields[2] == 'tennis' || fields[2] == 'pattinaggio' || fields[2] == 'piscina')){ 
                addToActivity(entries, fields[2], direction, val);
            } else if(activity == 'stipendi') {
                addToActivity(entries, activity + ' ' + fields[2], direction, val);
            } else {
                addToActivity(entries, activity, direction, val);
            }

            entries['totale'][direction]['banca'] += toVal(val.banca);  
            entries['totale'][direction]['contanti'] += toVal(val.contanti);  
            entries['totale'].count += val.count;


        }

        return entries;
    }

    function entryMatchesWhat(fields, what, mainOnly, word) {
        if (!word) {
            if ((mainOnly && fields[1] != what) || (!mainOnly && fields.indexOf(what)==-1)) {
                return false;
            }
            return true;
        } else {
            if ((mainOnly && fields[1].split(' ').indexOf(what) == -1) || (!mainOnly && !fields.find(f=>f.split(' ').indexOf(what)!=-1))) {
                return false;
            }
            return true;   
        }
    }

    function getActivityEntries(what, mainOnly = true, word = false) {
        let entries = [];
        let tot = {entry: 'totale', val: {banca:0, contanti: 0, count: 0}};
        for (let field in dataCtx.clubData.fields) {
            let fields = field.split('-').map(s=>s.trim());
            if (!entryMatchesWhat(fields, what, mainOnly, word)) continue;

            let val = dataCtx.clubData.fields[field];
            let direction = fields[0];
            let entry = fields.slice(1).filter(f=>f!=what).join(' ');
            if (!val.banca) val.banca = 0;
            if (!val.contanti) val.contanti = 0;
            entries.push({direction, entry, val})
            tot.val.count += val.count;
            tot.val.banca += toVal(val.banca) * (direction == 'entrata' ? 1 : -1);
            tot.val.contanti += toVal(val.contanti) * (direction == 'entrata' ? 1 : -1);
        }
        entries.push(tot);
        return entries;
    }

    function getActivityTitle(activity) {
        switch(activity) {
            case 'torneo':
                return 'Tornei di Tennis'
            default: 
                return activity.charAt(0).toUpperCase()+activity.slice(1);
        }
    }

    function renderActivity(activity, mainOnly = true, word = false){
        let entries = getActivityEntries(activity, mainOnly, word);

        return <Accordion open={alwaysOpen}>
        <AccordionHeader onClick={handleAlwaysOpen}>{getActivityTitle(activity)}</AccordionHeader>
        <AccordionBody>
            <div className="flex w-full font-semibold">
                            <div className="w-24"></div>
                            <div className="w-48"></div>
                            <div className="w-24">totale</div>
                            <div className="w-24">banca</div>
                            <div className="w-24">contanti</div>
            </div>                    
            {
                entries.map((entry,i)=>{
                    return <React.Fragment key={i}>
                        <div className={'flex pb-1 w-full'+ (entry.entry=='totale' ? ' font-semibold' : ' font-normal')}>
                            <div className="w-24">{
                                entry.direction ? (entry.direction=='entrata' ? <Chip className="text-center w-20" variant="ghost" size="sm" color="green" value="entrata" /> : <Chip className="text-center w-20" variant="ghost" size="sm" color="red" value="uscita" />)
                                : <></>
                            }
                            </div>
                            <div className="w-48">{entry.entry}({entry.val.count})</div>
                            <div className="w-24">{displayVal(entry.val.banca+entry.val.contanti)}</div>
                            <div className="w-24">{displayVal(entry.val.banca)}</div>
                            <div className="w-24">{displayVal(entry.val.contanti)}</div>
                        </div>                    
                    </React.Fragment>
                })
            }
        </AccordionBody>
        </Accordion>
    }

    return <TabPanel value="club">
        { dataCtx.clubData ?  <>
            <Accordion open={alwaysOpen}>
                <AccordionHeader onClick={handleAlwaysOpen}>Riepilogo</AccordionHeader>
                <AccordionBody>
                    
                    <div className="flex w-full font-semibold">
                        <div className="w-40"></div>
                        <div className="w-24">bilancio</div>
                        <div className="w-24">banca</div>
                        <div className="w-24">contanti</div>
                        <div className="w-24 text-green-500">entrate</div>
                        <div className="w-24 text-green-500">banca</div>
                        <div className="w-24 text-green-500">contanti</div>
                        <div className="w-24 text-red-500">uscite</div>
                        <div className="w-24 text-red-500">banca</div>
                        <div className="w-24 text-red-500">contanti</div>
                    </div>
                        {

                            Object.entries(getSummaryEntries()).reverse().map((entry,i)=>{
                                return <div className={'flex w-full'+ (entry[0]=='totale' ? ' font-semibold' : ' font-normal')} key={i}>

                                    <div className="w-40">{entry[0].length > 16 ? entry[0].substr(0,16)+'.' : entry[0]}({entry[1].count})</div>
                                    <div className="w-24">{balanceDisplayVal(entry[1].entrata.banca + entry[1].entrata.contanti - (entry[1].uscita.banca + entry[1].uscita.contanti))}</div>
                                    <div className="w-24">{balanceDisplayVal(entry[1].entrata.banca - entry[1].uscita.banca)}</div>
                                    <div className="w-24">{balanceDisplayVal(entry[1].entrata.contanti - entry[1].uscita.contanti)}</div>                                    
                                    <div className="w-24 text-green-500">{displayVal(entry[1].entrata.banca + entry[1].entrata.contanti)}</div>
                                    <div className="w-24 text-green-500">{displayVal(entry[1].entrata.banca)}</div>
                                    <div className="w-24 text-green-500">{displayVal(entry[1].entrata.contanti)}</div>
                                    <div className="w-24 text-red-500">{displayVal(entry[1].uscita.banca + entry[1].uscita.contanti)}</div>
                                    <div className="w-24 text-red-500">{displayVal(entry[1].uscita.banca)}</div>
                                    <div className="w-24 text-red-500">{displayVal(entry[1].uscita.contanti)}</div>
                                </div>
                            })
                        }
 
                </AccordionBody>
            </Accordion>  
            {
                renderActivity('tennis', false)
            }
            {
                renderActivity('pattinaggio', false)
            }
            {
                renderActivity('piscina', false)
            }
            {
                renderActivity('stipendi')
            }
            {
                renderActivity('affitto')
            }
            {
                renderActivity('altro')
            }{
                renderActivity('torneo', false, true)
            }
        </>
        :
        <div className="p-4"><i>Nessun dato caricato</i></div>
        }
        </TabPanel>
}