
import moment from 'moment';
export default new class {
	sleep(ms) {
		return new Promise(resolve => setTimeout(function(){
			resolve();
		}, ms));
	}
	toHumanDate (epoch) {
		var m = moment(new Date(epoch));
		return `${m.format('DD/M/Y')} alle ${m.format('HH:mm')}`;
	}
	timestampToMonthName(ts){
		const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
		];
		return monthNames[new Date(ts).getMonth()];
	}
	formatTwoDigits(n) {
		return n < 10 ? '0' + n : n;
	}
	timestampToHoursAndMinutes(ts){
		let d = new Date(ts);
		return `${this.formatTwoDigits(d.getHours())}:${this.formatTwoDigits(d.getMinutes())}`;
	}
	capitalize(val){
		return val.charAt(0).toUpperCase() + val.slice(1)
	}

}